import React from "react";
import {classNames} from "../tools/react.dom.helpers";
import {_t} from "../../../js/translation";
import {Icon} from "./index";

const Badge = ({icon, iconColor, type=Badge.TYPE.RECTANGLE, caption, title, color, children, className}) =>
    <span className={classNames(className, 'badge', color && 'badge--' + color, type && 'badge--' + type)} title={title && title}>
        { children ||
            <>
                { icon && <Icon icon={icon} color={iconColor} /> }
                { caption && <span className='badge__txt'>{_t(caption)}</span>}
            </> }
    </span>

Badge.TYPE = {
    RECTANGLE: '',
    PILL: 'pill',
    CIRCLE: 'circle',
    PLAIN: 'plain'
};

Badge.COLOR = {
    DANGER: 'danger',
    SUCCESS: 'success',
    WARNING: 'warning',
    PRIMARY: 'primary',
};

export default Badge;
