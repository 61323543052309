import React from 'react';
import {childrenArray, classNames} from "../../tools/react.dom.helpers";
import {Link} from "react-router-dom";

export default ({itemType, className, children, selected, onClick, link}) => (
    link ?
        <a href={link}>
            <div className={classNames('item', 'item__' + itemType, selected && '--selected', className)}>
                <div className="item-container">
                    <div className="item-row">
                        {childrenArray(children).map((child, i) =>
                            <ItemCol key={`item_${i}`} className={
                                child?.type == ItemBody ? "item-col__body" :
                                child?.type == ItemHeader ? "item-col__header" :
                                child?.type == ItemInfo ? "item-col__info-hint" :
                                child?.type == ItemControls ? "item-col__controls" :
                                child?.type == ItemIcon ? "item-col__picture" :
                                null}>
                                {child}
                            </ItemCol>
                        )}
                    </div>
                </div>
            </div>
        </a>
        :
        <div className={classNames('item', 'item__' + itemType, selected && '--selected', className)} onClick={onClick}>
            <div className="item-container">
                <div className="item-row">
                    {childrenArray(children).map((child, i) =>
                        <ItemCol key={`item_${i}`} className={
                            child?.type == ItemBody ? "item-col__body" :
                            child?.type == ItemHeader ? "item-col__header" :
                            child?.type == ItemInfo ? "item-col__info-hint" :
                            child?.type == ItemControls ? "item-col__controls" :
                            child?.type == ItemIcon ? "item-col__picture" :
                            null}>
                            {child}
                        </ItemCol>
                    )}
                </div>
            </div>
        </div>
)

export const ItemBody = ({className, ...props}) => <div className={classNames(className, 'item-body')} {...props} />
export const ItemHeader = ({className, ...props}) => <div className={classNames(className, 'item-header')} {...props} />
export const ItemInfo = ({className, ...props}) => <div className={classNames(className, 'item-info')} {...props} />
export const ItemControls = ({className, ...props}) => <div
    className={classNames(className, 'item-controls')} {...props} />
export const ItemIcon = ({className, ...props}) => <div className={classNames(className, 'item-icon')} {...props} />

const ItemCol = ({className, children}) =>
    <div className={classNames('item-col', className)}>
        {children}
    </div>

